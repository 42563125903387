import React, { useEffect, useState } from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import Header from './Header';
import TopMenu from './TopMenu';
import GlobalStyle from '../style/globalStyle';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {getContextRoot, getLanguageValue, getApiRoot, googleRecaptcha, getStatusHtml, navigateToPath, getExtensionByFileContent, getFileType, executeSingleRequest} from "../common/functions";
import CONSTANTS from '../common/constants';
import HeaderWithLogoBackground from "./HeaderWithLogoBackground";
import MainFooter from "./MainFooter";
import TableComponent from './TableComponent';
import { useParams } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import Loader from './Loader';
import { ACTIONS } from '../redux/actions';
import { CSS_COLORS } from '../common/cssColors';
import CropHandler from './CropHandler';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import ModalComponent from "./Modal";

function UpdateRejectedDataBodyComponent(props) {

    const dispatch = useDispatch();
    const isMobile = useMediaQuery(`(max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px)`);
    const [ isUpdateCompleted, setIsUpdateCompleted ] = useState(false);
    const [ loaders, setLoaders ] = useState({ init: true });
    const [ errors, setErrors ] = useState({ });
    const [ fieldsData, setFieldsData ] = useState({
        fields: lodashGet(props, 'rejectedApplicationData.fields') || {},
        files: getUploadedFiles() || {}
    });
    let [ cropHandlerControls , setCropHandlerControls ] = useState({
        passport: {},
        passportFace: {},
        birthCertificate: {},
        parentPassport: {}
    });
    cropHandlerControls.passport.callback = cropHandlerCallback.bind({ fileType: 'passport' });
    cropHandlerControls.passportFace.callback = cropHandlerCallback.bind({ fileType: 'passportFace' });
    cropHandlerControls.birthCertificate.callback = cropHandlerCallback.bind({ fileType: 'birthCertificate' });
    cropHandlerControls.parentPassport.callback = cropHandlerCallback.bind({ fileType: 'parentPassport' });
    const [ isModalOpened, setIsModalOpened] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            // quit the page if no data available on redux
            if (!lodashGet(props, 'rejectedApplicationData.formKey')) {
                navigateToPath(dispatch, getContextRoot());
                return;
            }

            // set all images to edit-mode by default
            const filesKeysList = [ 'passport', 'passportFace', 'birthCertificate', 'parentPassport'];
            for (let filesKey of filesKeysList) {
                if (fieldsData.files[filesKey]) {
                    cropHandlerControls[filesKey].restart();
                }
            }
        }, 10);
    }, []);

    return <>
        <GlobalStyle/>
        <UpdateRejectedDataBodyStyle>
            <Header className="header-component"/>
            <HeaderWithLogoBackground className="header-background-component" />
            <TopMenu className="top-menu-component" />
            <div className={"content-container"}>
                <h1>{getLanguageValue('update-rejected-data.title')} {lodashGet(props, 'rejectedApplicationData.formKey')}</h1>
                {lodashGet(props, 'rejectedApplicationData.groupKey') && <h2>{getLanguageValue('update-rejected-data.group-form')} - {lodashGet(props, 'rejectedApplicationData.groupKey')}</h2>}
                <h2>{getLanguageValue('update-rejected-data.fields-to-fix')}</h2>
                <div className="fields-to-fix-container">
                    {getLanguageValue('update-rejected-data.nothing-required')}
                </div>
                <h2>{getLanguageValue('update-rejected-data.files-to-fix')}</h2>
                <div className="files-to-fix-container">                  
                    {!!fieldsData.files.passport && <div className="file-to-fix-item passport">
                        <div className="upload-general-warning-message" dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage2-upload-general-warning') }} />
                        <Button className={"file-upload-button passport" + (errors.passport ? ' field-error' : '')} onClick={() => { document.querySelector('#passport-file-upload-field').click(); }}>{getLanguageValue('update-rejected-data.files-passport') + ' *'}</Button>
                        {errors.passport ? <div className={"file-upload-error"}>{errors.passport}</div> : null}
                        <br/><br/>
                        <input type="file" id="passport-file-upload-field" onChange={(e) => { onFileUploadChange(e, 'passport', '.file-to-fix-item.passport'); }} />
                        <div className={"image-component-container passport"}><img className="image-component passport" src={fieldsData.files.passport.fileContent} /></div>
                        <br/>
                        {lodashGet(fieldsData.files.passport, 'fileContent') && !lodashGet(fieldsData.files.passport, 'editMode') ? <div><Button className={"file-upload-edit-button"} onClick={() => { lodashSet(fieldsData.files.passport, 'editMode', true); cropHandlerControls.passport.restart(); setFieldsData({ ...fieldsData }); }}>{getLanguageValue('crop-handler-edit')}</Button></div> : null}
                        <CropHandler controls={cropHandlerControls.passport} dimensions={isMobile ? CONSTANTS.CROP_TOOL_DIMENSIONS['passportMobile'] : CONSTANTS.CROP_TOOL_DIMENSIONS['passportDesktop']} imageCssSelector={'.image-component.passport'} className="crop-handler passport" imageSrc={lodashGet(fieldsData.files.passport, 'fileContent')} />
                        <br/>
                        <img className={"sample-passport-image"} src={getContextRoot() + 'images/sample_passport.jpg'} />
                    </div>}
                    {!!fieldsData.files.passportFace && <div className="file-to-fix-item passport-face">
                        <br/><br/>
                        <div className={"upload-general-warning-message"} dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage2-upload-passport-face-note')}} />
                        <br/>
                        <Button className={"file-upload-button passport-face" + (errors.passportFace ? ' field-error' : '')} onClick={() => { document.querySelector('#passport-face-file-upload-field').click(); }}>{getLanguageValue('update-rejected-data.files-passportFace') + ' *'}</Button>
                        {errors.passportFace ? <div className={"file-upload-error"}>{errors.passportFace}</div> : null}
                        <br/><br/>
                        <input type="file" id="passport-face-file-upload-field" onChange={(e) => { onFileUploadChange(e, 'passportFace', '.file-to-fix-item.passport-face'); }} />
                        <div className={"image-component-container passport-face"}><img className="image-component passport-face" src={fieldsData.files.passportFace.fileContent} /></div>
                        <br/>
                        {lodashGet(fieldsData.files.passportFace, 'fileContent') && !lodashGet(fieldsData.files.passportFace, 'editMode') ? <div><Button className={"file-upload-edit-button passport-face"} onClick={() => { lodashSet(fieldsData.files.passportFace, 'editMode', true); cropHandlerControls.passportFace.restart(); setFieldsData({ ...fieldsData }); }}>{getLanguageValue('crop-handler-edit')}</Button></div> : null}
                        <CropHandler controls={cropHandlerControls.passportFace} dimensions={isMobile ? CONSTANTS.CROP_TOOL_DIMENSIONS['passportFaceMobile'] : CONSTANTS.CROP_TOOL_DIMENSIONS['passportFaceDesktop']} imageCssSelector={'.image-component.passport-face'} className="crop-handler passport-face" imageSrc={lodashGet(fieldsData.files.passportFace, 'fileContent')} />
                        <br/>
                        <img className={"sample-passport-face-image"} src={getContextRoot() + 'images/sample_passport_face.jpg'} />
                    </div>}
                    {!!fieldsData.files.birthCertificate && <div className="file-to-fix-item birth-certificate">
                        <br/><br/>
                        <div className={"upload-general-warning-message"} dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage2-upload-birth-certificate-note')}} />
                        <br/>
                        <Button className={"file-upload-button birth-certificate" + (errors.birthCertificate ? ' field-error' : '')} onClick={() => { document.querySelector('#birth-certificate-file-upload-field').click(); }}>{getLanguageValue('update-rejected-data.files-birthCertificate') + ' *'}</Button>
                        {errors.birthCertificate ? <div className={"file-upload-error"}>{errors.birthCertificate}</div> : null}
                        <br/><br/>
                        <input type="file" id="birth-certificate-file-upload-field" onChange={(e) => { onFileUploadChange(e, 'birthCertificate', '.file-to-fix-item.birth-certificate'); }} />
                        <div className={"image-component-container birth-certificate"}><img className="image-component birth-certificate" src={fieldsData.files.birthCertificate.fileContent} /></div>
                        <br/>
                        {lodashGet(fieldsData.files.birthCertificate, 'fileContent') && !lodashGet(fieldsData.files.birthCertificate, 'editMode') ? <div><Button className={"file-upload-edit-button birth-certificate"} onClick={() => { lodashSet(fieldsData.files.birthCertificate, 'editMode', true); cropHandlerControls.birthCertificate.restart(); setFieldsData({ ...fieldsData }); }}>{getLanguageValue('crop-handler-edit')}</Button></div> : null}
                        <CropHandler controls={cropHandlerControls.birthCertificate} dimensions={isMobile ? CONSTANTS.CROP_TOOL_DIMENSIONS['birthCertificateMobile'] : CONSTANTS.CROP_TOOL_DIMENSIONS['birthCertificateDesktop']} imageCssSelector={'.image-component.birth-certificate'} className="crop-handler birth-certificate" imageSrc={lodashGet(fieldsData.files.birthCertificate, 'fileContent')} />
                        <br/>
                        <img className={"sample-birth-certificate-image"} src={getContextRoot() + 'images/sample_birth_certificate.jpg'} />
                    </div>}
                    {!!fieldsData.files.parentPassport && <div className="file-to-fix-item parent-passport">
                        <br/>
                        <Button className={"file-upload-button parent-passport" + (errors.parentPassport ? ' field-error' : '')} onClick={() => { document.querySelector('#parent-passport-file-upload-field').click(); }}>{getLanguageValue('update-rejected-data.files-parentPassport') + ' *'}</Button>
                        {errors.parentPassport ? <div className={"file-upload-error"}>{errors.parentPassport}</div> : null}
                        <br/><br/>
                        <input type="file" id="parent-passport-file-upload-field" onChange={(e) => { onFileUploadChange(e, 'parentPassport', '.file-to-fix-item.parent-passport'); }} />
                        <div className={"image-component-container parent-passport"}><img className="image-component parent-passport" src={fieldsData.files.parentPassport.fileContent} /></div>
                        <br/>
                        {lodashGet(fieldsData.files.parentPassport, 'fileContent') && !lodashGet(fieldsData.files.parentPassport, 'editMode') ? <div><Button className={"file-upload-edit-button parent-passport"} onClick={() => { lodashSet(fieldsData.files.parentPassport, 'editMode', true); cropHandlerControls.parentPassport.restart(); setFieldsData({ ...fieldsData }); }}>{getLanguageValue('crop-handler-edit')}</Button></div> : null}
                        <CropHandler controls={cropHandlerControls.parentPassport} dimensions={isMobile ? CONSTANTS.CROP_TOOL_DIMENSIONS['parentPassportMobile'] : CONSTANTS.CROP_TOOL_DIMENSIONS['parentPassportDesktop']} imageCssSelector={'.image-component.parent-passport'} className="crop-handler parent-passport" imageSrc={lodashGet(fieldsData.files.parentPassport, 'fileContent')} />
                        <br/>
                        <img className={"sample-parent-passport-image"} src={getContextRoot() + 'images/sample_passport.jpg'} />
                    </div>}
                </div>
                <br/><br/><br/>
                <div className="buttons-container">
                    <Button disabled={isUpdateCompleted} className="general-style-update-button" onClick={() => { update(); }}>{getLanguageValue('register-common.stages-buttons-update')} {lodashGet(loaders, 'update') ? <Loader className={"update-loader"} /> : null}</Button>
                </div>
                {errors.update ? <div className={"update-error"}>{errors.update}</div> : null}
                <br/>
            </div>
            <ModalComponent
                isOpen={isModalOpened}
                modalClassName={'update-rejected-data-modal'}
                showTitleCloseButton={true}
                onClose={(src) => { setIsModalOpened(false) }}
                title={getLanguageValue('update-rejected-data.completed-title')}
                content={<ModalComponentStyle>
                    <ModalOverrideGlobalStyle />
                    <div className={'html-container'}>{getLanguageValue('update-rejected-data.completed-content')}</div>
                    <div id="modal-buttons"><Button className="close-button" onClick={() => { setIsModalOpened(false); }}>{getLanguageValue('need-help-section.modal-close')}</Button></div>
                </ModalComponentStyle>}
            />
            <MainFooter className="footer-component" />
        </UpdateRejectedDataBodyStyle>
    </>;


    function isLoading() {
        for (let key in loaders) {
            if (loaders[key]) {
                return true;
            }
        }
        return false;
    }

    async function update() {
        let validateResult = validate({ setErrors: true });
        if (validateResult.length) {
            setTimeout(function() {
                if (document.querySelectorAll('.field-error')[0]) {
                    document.querySelectorAll('.field-error')[0].scrollIntoView();
                }
            }, 50);
            return;
        }
        setLoaders({ ...loaders, update: true });
        try {
            let duplicateData = JSON.parse(JSON.stringify(fieldsData));
            // create separate file upload requests (one by one)
            let promises = [];
            for (let fileKey in duplicateData.files) {
                delete duplicateData.files[fileKey].changed;
                delete duplicateData.files[fileKey].editMode;
                let boundPromise = executeSingleRequest.bind({
                    url: getApiRoot() + 'update-rejected-data/?type=' + (props.rejectedApplicationData.groupKey ? 'group' : 'individual') + '&languageKey=' + props.languageKey,
                    requestBody: {
                        key: props.rejectedApplicationData.formKey,
                        groupKey: props.rejectedApplicationData.groupKey,
                        data: {
                            fields: [], // no fields
                            files: {
                                [fileKey]: duplicateData.files[fileKey]
                            }
                        }
                    }
                });
                // push final results
                promises.push(await boundPromise());
            }

            try {
                setErrors({ ...errors, update: false });
                let results = promises;
                setLoaders({ ...loaders, update: false });
                for (let result of results) {
                    if (!lodashGet(result, 'data.success')) {
                        setErrors({ ...errors, update: getLanguageValue('register-network-error') });
                        return;
                    }
                }

                // update redux and finish
                setIsUpdateCompleted(true);
                setIsModalOpened(true);

                //props.rejectedApplicationData.formKey
                if (props.rejectedApplicationData.groupKey) {
                    // group form
                    let isMatch = false;
                    for (let loop = 0 ; loop < props.linkedApplicationsData.length && !isMatch ; ++loop) {
                        if (props.linkedApplicationsData[loop].key == props.rejectedApplicationData.formKey) {
                            // matched
                            isMatch = true;
                            props.linkedApplicationsData[loop].rejectReasons = {};
                            props.linkedApplicationsData[loop].status = 'paid';
                        }
                    }
                    dispatch({
                        type: ACTIONS.GENERIC_SET_VALUE,
                        payload: [
                            {
                                path: 'rejectedApplicationData',
                                value: null
                            },
                            {
                                path: 'registerGroupStagesData.stage2Data.linkedIndividualApplications',
                                value: [ ...props.linkedApplicationsData ]
                            }]
                    });
                }
                else {
                    // individual form
                    dispatch({
                        type: ACTIONS.GENERIC_SET_VALUE,
                        payload: [
                            {
                                path: 'rejectedApplicationData',
                                value: null
                            },
                            {
                                path: 'registerIndividualStagesData.rejectReasons',
                                value: {}
                            },
                            {
                                path: 'registerIndividualStagesData.status',
                                value: 'paid'
                            }]
                    });
                }
            }
            catch (err) {
                // nothing to do
                setLoaders({ ...loaders, update: false });
                setErrors({ ...errors, update: getLanguageValue('register-network-error') });
                return;
            }
        }
        catch (err) {
            setLoaders({ ...loaders, update: false });
            setErrors({ ...errors, update: getLanguageValue('register-network-error') });
        }
    }

    function getUploadedFiles() {
        let output = {};
        if (lodashGet(props, 'rejectedApplicationData.files') && Object.keys(props.rejectedApplicationData.files).length) {
            for (let fileType in props.rejectedApplicationData.files) {
                output[fileType] = {
                    ...props.rejectedApplicationData.files[fileType],
                    changed: false,
                    editMode: true
                };
            }
        }
        return output;
    }

    function onFileUploadChange(e, fieldName, containerCssSelector) {
        const file = e.target.files[0];
        const localFilename = file.name;
        const fileExtension = localFilename.split('\.')[localFilename.split('\.').length - 1].toLowerCase();
        const reader = new FileReader();
        // reset to allow selecting same filename
        e.target.value = '';

        reader.addEventListener("load", async function () {
            // validate
            let fileTypeResult = await getFileType(reader.result);
            let fileTypeResultExtension = lodashGet(fileTypeResult, 'ext');
            if (['jpg', 'jpeg', 'png', 'bmp', 'heic'].indexOf(fileTypeResultExtension) == -1) {
                setErrors({ ...errors, [fieldName]: getLanguageValue('register-individual.stage2-upload-passport-type-error') });
                setFieldsData({ ...fieldsData, files: { ...fieldsData.files, [fieldName]: { fileContent: '' } } });
                return;
            }
            if (reader.result.length < CONSTANTS.FILESIZE_MIN_LIMIT) {
                setErrors({ ...errors, [fieldName]: getLanguageValue('register-individual.stage2-upload-passport-too-small-error') });
                setFieldsData({...fieldsData, files: { ...fieldsData.files, [fieldName]: { fileContent: '' } } });
                return;
            }
            if (reader.result.length > CONSTANTS.FILESIZE_MAX_LIMIT) {
                setErrors({ ...errors, [fieldName]: getLanguageValue('register-individual.stage2-upload-passport-size-error') });
                setFieldsData({...fieldsData, files: { ...fieldsData.files, [fieldName]: { fileContent: '' } } });
                return;
            }
            setErrors({ ...errors, [fieldName]: false });
            // close previous crop tool if have any
            if (document.querySelector(containerCssSelector + ' .image-crop-buttons-container .crop-finish-button')) {
                document.querySelector(containerCssSelector + ' .image-crop-buttons-container .crop-finish-button').click();
            }
            // activate crop tool
            setTimeout(() => {
                setFieldsData({...fieldsData, files: { ...fieldsData.files, [fieldName]: { fileContent: reader.result, fileExtension: getExtensionByFileContent(reader.result), fileType: fieldName, editMode: true, changed: true } } });
                setTimeout(() => {
                    cropHandlerControls[fieldName].restart();
                }, 100);
            }, 100);
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    function validate(config) {
        let output = [];
        let localErrors = {};
        let filesList = [ 'passport', 'passportFace', 'birthCertificate', 'parentPassport' ];
        for (let fileKey of filesList) {
            if (errors[fileKey]) {
                output.push(fileKey);
            }
            else if (lodashGet(fieldsData, 'files.' + fileKey) && !lodashGet(fieldsData, 'files.' + fileKey + '.changed')) {
                output.push(fileKey);
                if (lodashGet(config, 'setErrors')) {
                    localErrors[fileKey] = getLanguageValue('register-field-errors-must-upload');
                }
            }
        }
        if (lodashGet(config, 'setErrors') && Object.keys(localErrors).length) {
            setErrors(localErrors);
        }
        return output;
    }

    function cropHandlerCallback(croppedImage) {
        lodashSet(fieldsData.files[this.fileType], 'editMode', false);
        setErrors({ ...errors, [this.fileType]: false });
        if (croppedImage !== false) {
            fieldsData.files[this.fileType].fileContent = croppedImage;
            lodashSet(fieldsData.files[this.fileType], 'changed', true);
        }
        setFieldsData({ ...fieldsData });
    }
}

const ModalComponentStyle = styled.div`
  #modal-buttons {
    background-color: lightgray;
    text-align: center;
    padding: 7px;
  }
  .close-button {
    background-color: #fc3;
  }
  .modal-bottom-spacer {
    height: 20px;
  }
`;

const ModalOverrideGlobalStyle = createGlobalStyle`
  #modal-component.update-rejected-data-modal {
    #modal-title {
      font-size: 33px;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        font-size: 24px;
      }
    }
    #modal-content {
      padding: 0 !important;
      font-size: 22px;
    }
    .html-container {
      padding: 10px 10px;
    }
  }
`;

const UpdateRejectedDataBodyStyle = styled.div`
  .content-container {
    padding-right: 20px;
    padding-left: 20px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      padding: 20px 10%;
    }
  }
  h1 {
    text-align: center;
  }
  .loader-container {
    text-align: center;
  }
  .sample-passport-image, .sample-passport-face-image, .sample-birth-certificate-image, .sample-parent-passport-image {
    max-width: 100%;
  }
  .upload-general-warning-message {
    max-width: 500px;
    color: ${CSS_COLORS.FIELD_WARNING};
    margin-bottom: 5px;
    text-align: right;
    html.ltr & {
      text-align: left;
    }
  }
  .image-component-container {
    max-width: 500px;
  }
  .image-component-container.passport .image-component {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      max-width: 100%;
      height: auto;
    }
  }
  .image-component-container.passport-face .image-component {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      max-width: 100%;
      height: auto;
    }
  }
  .image-component-container.birth-certificate .image-component {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      max-width: 100%;
      height: auto;
    }
  }
  .image-component-container.parent-passport .image-component {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      max-width: 100%;
      height: auto;
    }
  }
  .file-upload-button {
    width: 500px;
    height: 50px;
    border: 1px solid black;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: 100%;
    }
    &.field-error {
      border-color: ${CSS_COLORS.FIELD_WARNING};
      color: ${CSS_COLORS.FIELD_WARNING};
    }
  }
  .file-upload-error {
    color: ${CSS_COLORS.FIELD_WARNING};
    margin-top: 15px;
  }
  #passport-file-upload-field, #passport-face-file-upload-field, #birth-certificate-file-upload-field, #parent-passport-file-upload-field {
    display: none;
  }
  .file-upload-edit-button {
    width: 500px;
    border: 1px solid black;
    &.passport-face {
      width: 350px;
    }
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: 100% !important;
    }
  }
  .image-component {
    border: 1px dashed black;
    &.passport {
      max-width: 500px;
    }
    &.passport-face {
      max-width: 350px;
    }
    &.birth-certificate {
      max-width: 600px;
    }
    &.parent-passport {
      max-width: 500px;
    }
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: 100% !important;
    }
  }
  .image-component-container {
    width: 100%;
  }
  .update-loader {
    color: white;
  }
  .update-error {
    margin-top: 10px;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
`;


const UpdateRejectedDataBody = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        rejectedApplicationData: state.rejectedApplicationData,
        individualStagesData: lodashGet(state, 'registerIndividualStagesData'),
        linkedApplicationsData: lodashGet(state, 'registerGroupStagesData.stage2Data.linkedIndividualApplications') || [],
    }),
    {})(UpdateRejectedDataBodyComponent);

export default UpdateRejectedDataBody;
